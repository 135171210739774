
<template>
    <div class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
            <v-col sm="12" class="ma-0 pa-0">
                <div class="primary-section-content">
                    <v-card elevation="1" class="ma-0 pa-6 mt-5">
                        <v-row class="ma-0 pa-0 px-1">
                            <v-col class="pa-0">
                                <span class="hb-larger-font-size font-weight-medium">{{ active_group.name }}</span>
                            </v-col>
                            <v-col md="3 text-right pt-0 pa-0">
                                <a @click="editProperties">Edit Properties</a>
                            </v-col>
                        </v-row>
                         <v-row class="ma-0 pa-0 px-1">
                            <v-col class="pa-0">
                                <p>Assigned to 
                                    {{ filteredProperties(active_group.Properties).length }} {{ filteredProperties(active_group.Properties).length  === 1 ? 'Property' : 'Properties' }}
                                </p>
                            </v-col>
                        </v-row>
                        <div>
                            <hb-data-table
                                hide-default-header
                                :headers="stageHeaders"
                                :items="triggersv2"
                                @click:row="editStage"
                            >
                                <template v-slot:header="">
                                    <thead>
                                        <tr >
                                            <th style="font-size: 14px; font-weight: 500; background-color: #F4F6F8;">Day</th>
                                            <th style="font-size: 14px; font-weight: 500; width: 60%; background-color: #F4F6F8;">Stage Name</th>
                                            <th
                                                colspan="2"
                                                class="text-right"
                                                style="color: #00848E; font-size: 14px; font-weight: normal; cursor: pointer; background-color: #F4F6F8;"
                                                @click.stop="addStage"
                                            ><span class="hb-link hb-default-font-size">+ Add New Stage</span></th>
                                        </tr>
                                    </thead>
                                </template>

                                <template v-slot:item.start="{ item }">Day {{ item.start + 1}}</template>
                                <template v-slot:item.name="{ item }"><strong>{{ item.name }}</strong><br />{{ item.description }}</template>
                                <template v-slot:item.actionsCount="{ item }"> 
                                    
                                     <hb-tooltip>
                                            <template v-slot:item>
                                                <span style="display: inline-block; border-bottom: 1px dashed #101318; padding-bottom: 2px;"> 
                                                    {{  item.Actions.length }} {{  item.Actions.length  === 1 ? 'Action' : 'Actions' }}
                                                </span>
                                            </template>
                                        <template v-slot:header>
                                            List of actions in this stage.
                                        </template>
                                        <template v-slot:body>
                                            <div v-for="a in item.Actions" :key="a.id">
                                                <div>{{ a.name }}</div>
                                            </div>
                                        </template>
                                     </hb-tooltip>
                    
                                    
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <hb-menu
                                        options
                                        align-right
                                        >

                                        <v-list>
                                            <v-list-item
                                                @click="editStage(item)"
                                                v-if="hasDelinquencyEditPermission"
                                            >
                                                Edit
                                            </v-list-item>

                                            <v-list-item
                                                @click="deleteStage(item)"
                                            >
                                                Delete
                                            </v-list-item>
                                        </v-list>
                                    </hb-menu>
                                </template>
                            </hb-data-table>
                        </div>
                    </v-card>
                </div>
            </v-col>
        </v-row>

    <payments-notification-modal
      v-model="notificationModal"
      notificationType="manage_delinquency_stages"
    >
    </payments-notification-modal>       

    </div>
</template>
            

<script type="text/babel">

import api from "../../../assets/api.js";
import { mapGetters, mapActions } from "vuex";
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';




export default {
    name: "AutomationGroupProperties",
    data() {
        return {
            showAside: false,
            showEdit: false,
            selected: {
                properties: []
            },
            selected_stage: {}, 
            stageHeaders: [
                {
                    text: "Day",
                    value: "start"
                },
                {
                    text: "Stage Name",
                    value: "name"
                },
                {
                    text: "",
                    value: "actionsCount",
                    align: "right",
                    sortable: false
                },
                {
                    text: "",
                    value: "actions",
                    align: "right",
                    sortable: false
                }
            ],
            active_group: {}, 
            notificationModal: false,
            
        };
    },
    components: {
        PaymentsNotificationModal
    },
    props: ["group", "triggersv2"],
    created() {

        //this.getGroups();
        // this.getGroupById({ id: this.$props.groupID });
        this.active_group = this.group;
        //this.getTriggersByGroup();
    },
    mounted() {},
    computed: {
        ...mapGetters({
            properties: 'propertiesStore/filtered',
            hasPermission: 'authenticationStore/rolePermission'
        }),
        hasDelinquencyEditPermission(){
            return this.hasPermission("manage_delinquency_stages");
        }        
    },
    watch: {
        // groups(n, o) {
        //     if (n) {
        //         this.getGroupById({ id: this.$props.groupID });
        //     }
        // }
    },
    methods: {
        ...mapActions({
            //getGroups: "triggersStore/getGroups",
            // getGroupById: "triggersStore/getGroupById",
            // getTriggersByGroup: "triggersStore/getTriggersByGroup",
            // deleteTrigger: "triggersStore/deleteTrigger",
            // getTriggerById: "triggersStore/getTriggerById"
        }),
        editProperties(){
            this.$emit('editProperties', this.active_group)
        }, 
        filteredProperties(properties){ 
            return this.properties.filter(p => properties.find(prop => prop.id === p.id)); 
        }, 
        editStage(stage) {
            if(!this.hasDelinquencyEditPermission) {
                this.notificationModal = true;
                return false;
            } 
            this.$emit('manageStage', stage); 
        },
        addStage() {
            this.$emit('manageStage'); 
        },
        deleteStage(stage) {
            console.log("stage", stage)
            this.$emit('deleteStage', stage); 
        },
        
        
        

    }
};
</script>

<style>
.subdued {
    color: #a5adb4;
    line-height: 18px;
}
</style>